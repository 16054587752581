import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import Screen from '@alpine-collective/toolkit-screen'

(() => {
  function submit_form_pickerpage(id, submit_form_id = null, doSubmit = false) {
    const form = document.getElementById(id)
    if (!form) return

    if (!!document.getElementById("submit_form_id") && typeof submit_form_id === "string") {
      document.getElementById("submit_form_id").value = submit_form_id
    } else {
      document.getElementById("submit_form_id").value = ""
    }

    const submit = form.querySelector('.submit')
    if (submit) {
      submit.disabled = "true"
      const loader = submit.querySelector("span")
      if (loader) {
        loader.classList.remove("hidden")
      }
    }

    if (doSubmit) {
      form.submit()
    }
  }

  function scrollToAnchor_pickerpage(event, anchorId, offset = 0) {
    event.preventDefault();
    offset = document.querySelector(`header`) ? document.querySelector(`header`).getBoundingClientRect().height : offset
    var anchor = document.getElementById(anchorId);
    if (anchor) {
      var elementPosition = anchor.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }

  window.scrollToAnchor_pickerpage = scrollToAnchor_pickerpage
  window.submit_form_pickerpage = submit_form_pickerpage
})();

Alpine.plugin(collapse);
Alpine.plugin(Screen);
Alpine.start();
